var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-data-table',_vm._b({attrs:{"add":false,"tabs":_vm.tabs,"title":_vm.$t('account-management'),"tabSliders":_vm.tabSliders,"tab-slider":_vm.tabSlider,"current-tab":_vm.currentTab,"offers":"","translate":""},on:{"update:tabSlider":function($event){_vm.tabSlider=$event},"update:tab-slider":function($event){_vm.tabSlider=$event},"update:currentTab":function($event){_vm.currentTab=$event},"update:current-tab":function($event){_vm.currentTab=$event},"view":_vm.onGotoView,"update":_vm.onGotoUpdate,"remove":_vm.onInitRemove,"status":_vm.onToggleStatus},scopedSlots:_vm._u([{key:"item.serial",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(_vm.currentTableAttrs.provider.perPage * (_vm.currentTableAttrs.provider.currentPage - 1) + index + 1)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('div',{staticClass:"tw-capitalize tw-text-sm tw-font-semibold",class:item.status == 'active' ? 'tw-text-AA' : 'tw-text-72'},[_vm._v(" "+_vm._s(item.status)+" ")])])]}},{key:"item.approved",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-btn',{staticClass:"tw-rounded-lg ",attrs:{"width":"","height":"44","outlined":"","color":"#F2CA51"},on:{"click":function($event){return _vm.onClickApproval(item)}}},[_vm._v(" "+_vm._s(_vm.$t('approval'))+" ")])],1)]}}],null,true)},'base-data-table',_vm.currentTableAttrs,false)),_c('v-menu',{attrs:{"top":"","right":"","nudge-top":"56"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"tw-right-6",attrs:{"fab":"","fixed":"","bottom":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"to":"/admin/buyers/register"}},[_vm._v(" "+_vm._s(_vm.$t('register-buyer'))+" ")]),_c('v-list-item',{attrs:{"to":"/admin/suppliers/register"}},[_vm._v(" "+_vm._s(_vm.$t('register-supplier'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }