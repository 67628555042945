<template>
	<div>
		<base-data-table
			v-bind="currentTableAttrs"
			:add="false"
			:tabs="tabs"
			:title="$t('account-management')"
			:tabSliders="tabSliders"
			:tab-slider.sync="tabSlider"
			:current-tab.sync="currentTab"
			offers
			translate
			@view="onGotoView"
			@update="onGotoUpdate"
			@remove="onInitRemove"
			@status="onToggleStatus"
		>
			<template v-slot:[`item.serial`]="{ index }">
				{{
					currentTableAttrs.provider.perPage *
						(currentTableAttrs.provider.currentPage - 1) +
						index +
						1
				}}
			</template>

			<template v-slot:[`item.status`]="{ item }">
				<div>
					<div
						:class="item.status == 'active' ? 'tw-text-AA' : 'tw-text-72'"
						class="tw-capitalize tw-text-sm tw-font-semibold"
					>
						{{ item.status }}
					</div>
				</div>
			</template>

			<template v-slot:[`item.approved`]="{ item }">
				<div>
					<v-btn
						class="tw-rounded-lg "
						width=""
						height="44"
						outlined
						@click="onClickApproval(item)"
						color="#F2CA51"
					>
						{{ $t('approval') }}
					</v-btn>
				</div>
			</template>
		</base-data-table>

		<v-menu top right nudge-top="56">
			<template #activator="{ on, attrs }">
				<v-btn
					v-on="on"
					v-bind="attrs"
					fab
					fixed
					bottom
					color="primary"
					class="tw-right-6"
				>
					<v-icon>mdi-plus</v-icon>
				</v-btn>
			</template>
			<v-list dense>
				<v-list-item to="/admin/buyers/register">
					{{ $t('register-buyer') }}
				</v-list-item>
				<v-list-item to="/admin/suppliers/register">
					{{ $t('register-supplier') }}
				</v-list-item>
			</v-list>
		</v-menu>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
	name: 'Management',
	data: () => ({
		currentTab: 'buyers',
		tabSlider: 'buyers',
	}),
	computed: {
		...mapGetters('buyers', [
			'$buyers',
			'$activeBuyers',
			'$pendingBuyers',
			'$rejectedBuyers',
		]),
		...mapGetters('suppliers', [
			'$suppliers',
			'$activeSuppliers',
			'$pendingSuppliers',
			'$rejectedSuppliers',
		]),
		actions() {
			return [
				{
					icon: 'mdi-eye-outline',
					event: 'view',
					text: this.$t('view-details'),
				},
				{
					icon: 'mdi-pencil',
					event: 'update',
					text: this.$t('edit-information'),
				},
				{
					icon: 'mdi-trash-can',
					color: 'error',
					event: 'remove',
					text: this.$t('actions.delete'),
				},
				{
					icon: 'mdi-checkbox-intermediate',
					event: 'status',
					text: this.$t('activate-deactivate'),
				},
			]
		},
		currentTableAttrs() {
			return {
				buyers: {
					name: 'Buyer-List',
					headers: this.filterHeader,
					provider: this.$activeBuyers,
					fetchFunction: this.getActiveBuyers,
					actions: this.actions,
				},
				approval: {
					name: this.$t('registration-approval'),
					actions: [],
					headers: this.filterHeader,
					provider: this.$pendingBuyers,
					fetchFunction: this.getPendingBuyers,
				},
				rejectedBuyers: {
					name: this.$t('rejected'),
					actions: this.actions,
					headers: this.rejectedHeaders,
					provider: this.$rejectedBuyers,
					fetchFunction: this.getRejectedBuyers,
				},
				suppliers: {
					name: 'Supplier-List',
					actions: this.actions,
					headers: this.filterHeader,
					provider: this.$activeSuppliers,
					fetchFunction: this.getActiveSuppliers,
				},
				'supplier-pending-approvals': {
					name: this.$t('registration-approval'),
					actions: [],
					headers: this.filterHeader,
					provider: this.$pendingSuppliers,
					fetchFunction: this.getPendingSuppliers,
				},
				rejectedSuppliers: {
					name: this.$t('rejected'),
					actions: this.actions,
					headers: this.rejectedHeaders,
					provider: this.$rejectedSuppliers,
					fetchFunction: this.getRejectedSuppliers,
				},
			}[this.tabSlider]
		},
		tabs() {
			return [
				{ text: this.$t('headers.supplier'), value: 'suppliers' },
				{ text: this.$t('buyerText'), value: 'buyers' },
			]
		},
		tabSliders() {
			return {
				buyers: [
					{ text: this.$t('buyer-list'), value: 'buyers' },
					{ text: this.$t('registration-request'), value: 'approval' },
					{ text: this.$t('rejected'), value: 'rejectedBuyers' },
				],
				suppliers: [
					{ text: this.$t('supplier-list'), value: 'suppliers' },
					{
						text: this.$t('registration-request'),
						value: 'supplier-pending-approvals',
					},
					{ text: this.$t('rejected'), value: 'rejectedSuppliers' },
				],
			}[this.currentTab]
		},
		rejectedHeaders() {
			return [
				{ text: 'headers.ns', value: 'serial' },
				{
					text: 'headers.companyEntityName',
					value: 'company.name',
					class: 'tw-whitespace-nowrap',
				},
				{
					text: 'headers.phone',
					value: 'company.phone',
					class: 'tw-whitespace-nowrap',
				},
				{
					text: 'headers.email',
					value: 'company.email',
					class: 'tw-whitespace-nowrap',
				},
				{ text: 'headers.reasons', value: 'rejectReasons.0.name' },
				{ text: 'notes', value: 'notes' },
				{ text: 'headers.status', value: 'status' },
				{ text: 'headers.actions', value: 'actions' },
			]
		},
		filterHeader() {
			let arr = [
				{ text: 'headers.ns', value: 'serial' },
				{ text: 'headers.companyEntityName', value: 'company.name' },
				{ text: 'headers.phone', value: 'company.phone' },
				{ text: 'headers.email', value: 'company.email' },
				{ text: 'headers.status', value: 'status' },
			]
			if (this.tabSlider.includes('approval')) {
				arr.push({ text: 'headers.approval', value: 'approved' })
			}
			// if (this.tabSlider != 'approval')
			else {
				arr.push({ text: 'headers.actions', value: 'actions' })
			}
			return arr
		},
	},
	methods: {
		...mapActions('suppliers', [
			'getSupplier',
			'getActiveSuppliers',
			'getPendingSuppliers',
			'getRejectedSuppliers',
			'supplierApprove',
			'getSingleSupplier',
			'deleteSupplier',
			'changeSupplierStatus',
		]),
		...mapActions('buyers', [
			'getBuyer',
			'getActiveBuyers',
			'getPendingBuyers',
			'getRejectedBuyers',
			'getSingleBuyer',
			'deleteBuyer',
			'changeBuyerStatus',
		]),
		onGotoView(event) {
			this.$router.push({
				path: `${this.currentTab}/${event.id}`,
			})
		},
		onClickApproval(item) {
			this.$router.push({
				path: `${this.currentTab}/${item.id}/approve`,
			})
		},
		onGotoUpdate(event) {
			this.$router.push({
				path: `${this.currentTab}/${event.id}/update`,
			})
		},
		onToggleStatus(event) {
			this.$root.confirmation = {
				title: this.$t('status-change-confirmation'),
				message: this.$t('sure-to-change-status-of-company', [
					event.company.name,
				]),
				handler: async () => {
					const action =
						this.currentTab === 'buyers'
							? this.changeBuyerStatus
							: this.changeSupplierStatus
					const [err] = await action({
						id: event.id,
						status: event.status === 'active' ? 'deactive' : 'active',
					})
					if (err) {
						this.$toast.error(
							err.message || this.$t('update-failed', [this.currentTab])
						)
						return
					}
					this.$toast.success(
						this.$t('updated-successfully', [this.currentTab])
					)
				},
			}
		},
		onInitRemove(event) {
			this.$root.confirmation = {
				title: this.$t('delete-confirmation'),
				message: this.$t('sure-to-delete', [
					this.currentTab === 'buyers'
						? this.$t('buyerText')
						: this.$t('supplierText'),
				]),
				handler: async () => {
					const actions =
						this.currentTab === 'buyers'
							? this.deleteBuyer
							: this.deleteSupplier
					const [err] = await actions(event.id)

					if (err) {
						this.$toast.error(
							err.message || this.$t('delete-failed', [this.currentTab])
						)
						return
					}
					this.$toast.success(
						this.$t('deleted-successfully', [this.currentTab])
					)
				},
			}
		},
	},
}
</script>
